module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.6_babel-eslint@10.1.0_eslint@9.4.0__esbuild@0.17.19_kcx64wroxnsus32hkmnnmtfq7m/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.svg","name":"Laura Knox Tattoo","short_name":"Laura Knox","start_url":"/","background_color":"#000000","theme_color":"#ffffff","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5f050032ada4eeba7296ed4bb84c493d"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.13.2_gatsby@5.13.6_babel-eslint@10.1.0_eslint@9.4.0__esbuild@0.17.19__x6z6ucg73l4pebqh5iokpokn4a/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.6_babel-eslint@10.1.0_eslint@9.4.0__esbuild@0.17.19_eslint-import-resolver-typesc_wwlyw5z6e5nacfpt7ujlsmr7hu/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
